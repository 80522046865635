(function ($) {
  Drupal.behaviors.productReplenishmentSelect = {
    attach: function (context) {
      // Add replenishment menu event if enable_replenishment=true in config.
      if (!Drupal.settings.common || !Drupal.settings.common.has_replenishment) {
        return null;
      }

      // When the replenishment select changes, update the add-to-bag
      // button's data-replenishment value.
      $(document).on('init.autoreplinish_select', function (e, addBtn, replenishmentSelect) {
        $(replenishmentSelect).on('change', function () {
          $(addBtn).data('replenishment', this.value);
        });

        // Set add-to-bag default replenishment value to 0.
        $(addBtn).data('replenishment', 0);
      });

      var $sppAddBtn = $('.js-add-to-cart', '.js-product');
      var $sppReplenishmentSelect = $('.js-replenishment-select', context);

      $(document).trigger('init.autoreplinish_select', [$sppAddBtn, $sppReplenishmentSelect]);
    }
  };
})(jQuery);
